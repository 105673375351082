.wild-mode .main h1,
.wild-mode .main h2,
.wild-mode .main h3,
.wild-mode .main h4,
.wild-mode .main h5,
.wild-mode .main h6,
.wild-mode .title {
    text-shadow: none;
}
.wild-mode .local-info {
    box-shadow: none;
}
