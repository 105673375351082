@charset "UTF-8";

html {
    font-size: calc(1vh + 1vw)
}

body {
    margin     : 0;
    word-break : break-all;
    box-sizing : border-box;
    font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif;
    overflow: hidden;
}

img {
    height   : auto;
    width    : 100%;
    max-width: 40vw;
}

.clearfix::after {
    content: "";
    display: table;
    clear  : both;
}

.left {
    float: left;
}

.right {
    float: right;
}

.hide {
    height : 0;
    opacity: 0;
}

.btn {
    text-decoration: none;
    cursor: pointer;
}

.control {
    list-style-type: none;
    margin         : 0;
    padding        : 0;
}

.no-break {
    overflow     : hidden;
    text-overflow: ellipsis;
    white-space  : nowrap;
}

a:hover {
    text-decoration: none !important;
}

body.loaded {
    .top {
        display: none;
    }
    .bottom {
        display: none;
    }
}


.top img.logo, .bottom img.logo {
    display: block;
    margin: 0 auto;
    position: relative;
}
.top, .bottom {
    position: absolute;
    width: 100vw;
    height: 50vh;
    z-index: 99999;
    overflow: hidden;
}
.top {
    background: @dark_main_bg;
    top: calc(-50vh - 100px);
    img.logo {
        bottom: -100%;
        transform: translateY(-50%);
    }
}

.bottom {
    background: @dark_main_bg;
    top: calc(100vh + 100px);
    img.logo {
        transform: translateY(-50%);
    }

}

.header,
.footer {
    height: 5vh;
}

.header a,
.footer a {
    line-height  : 5vh;
    display      : block;
    padding-left : 0.8rem;
    padding-right: 0.8rem;
}

.header>ul,
.footer ul {
    margin: 0 1vw;
}

.menu>li,
.footer li {
    float: left;
}

.menu>li {
    position: relative;
}

.footer .social {
    float: left;
}

.footer .status {
    float: right;
}

.middle {
    height: 90vh;
}

.sidebar {
    width: 20vw;
    float: left;
}

.main {
    margin-left: 20vw;
    box-sizing : border-box;
    padding    : 2vw 3vw;
    overflow-y : auto;
    position   : relative;
}

.sidebar,
.main {
    height: 100%;
}

.sidebar.hide {
    display: none;
}

.sidebar.hide+.main {
    margin-left: 0 !important;
}

.intro {
    text-align: center;
    position  : absolute;
    top       : 50%;
    left      : 0;
    right     : 0;
    transform : translateY(-50%);
}

.intro img.logo {
    display: inline;
}

.header ul ul {
    display : none;
    position: absolute;
    top     : 100%;
    left    : 0;
    width   : 14em;
    z-index : 999;
}

.header li:hover>ul {
    display: block;
}



.main {
    font-size  : 1rem;
    line-height: 2;
}

.header .sub-menu a {
    padding: 0 2vw;
}

.sidebar {
    overflow-y: auto;
}


.sidebar a {
    overflow     : hidden;
    text-overflow: ellipsis;
    white-space  : nowrap;
}

.sidebar a,
.taxonomy-key {
    display: block;
}

.sidebar ul {
    margin-top: 1rem;
}

.sidebar a,
.taxonomy-key {
    display    : block;
    line-height: 2;
    padding    : 0 4%;
}

.logo-link {
    height     : 5vh;
    box-sizing : border-box;
    padding-top: 1.2vh;
}


.logo {
    height : 70%;
    width  : auto;
    display: block;
}

.logo-container {
    display: none;
}
body.wild-mode .logo-wild {
    display: list-item;
}
body.light-mode .logo-light {
    display: list-item;
}
body.dark-mode .logo-default {
    display: list-item;
}
body.wild-mode .intro .logo-wild {
    display: block;
}
body.light-mode .intro .logo-light {
    display: block;
}
body.dark-mode .intro .logo-default {
    display: block;
}
hr {
    margin: 1rem 0;
}


pre {

    overflow: auto;
    padding : 0.8rem 1rem;
}

code {
    overflow   : auto;
    font-size  : 0.8rem;
    font-family: 'Fira Code', Inconsolata, 'Deja Vu Sans Mono', 'Source Code Pro', Consolas, monospace;
    padding    : 0.2rem 0.6rem;
}

.icon {
    width  : 1rem;
    height : 1rem;
    display: inline-block;
}

.footer .icon {
    position: relative;
    top     : 0.2rem;
}

.sidebar .icon {
    margin-right: 0.5rem;
    position    : relative;
    top         : 0.1rem;
}

.article-meta .icon {
    position: relative;
    top     : calc(0.1rem + 2px);
}

.article-tags .icon {
    position: relative;
    top     : -0.2rem;
}

.article-categories li:first-child .icon {
    top: calc(0.1rem + 1px);
}

.article {
    text-align: left;
}



.title {
    font-weight: bold;
    margin     : 0 0 0.5rem 0;
}

.item {
    margin-bottom: 1rem;
}

.item .title {
    margin: 0;
}

.article-meta {
    font-size    : 0.8rem;
    margin-bottom: 1rem;
}

.article-meta ul {
    list-style-type: none;
    margin         : 0;
}

.article-meta-splitter {
    margin-bottom: 0.5rem;
}

.article-meta li {
    float        : left;
    margin-right : 1rem;
    margin-bottom: 0.5rem;
}

.article-meta li:first-child {
    margin-right: 0.5rem;
}

.main .article-tags a {

    padding      : 0rem 0.8rem;
    border-radius: 1px;
    display      : block;
    line-height  : 1.4;
}



.main p a {
    margin: 0 0.3rem;
}

.title a,
.article-meta a,
.list a,
a.terms-title,
.terms-list a,
.pagination a {
    text-decoration: none;
}

.terms-list a {
    font-size: 0.8rem;
}

.pagination a {
    font-size: 0.8rem;
    padding  : 0 0.5rem;
}

.pagination {
    position: absolute;
    left    : 0;
    right   : 0;
    bottom  : 0;
    padding : 0 2rem;
}

.pagination ul {
    list-style-type: none;
    margin-left    : 0;
}

/* prism toolbar */
.code-toolbar>.toolbar a {
    border-bottom: none;
    display      : block;
}

.local-info {
    font-size    : 0.8rem;
    padding      : 0.8rem;
    margin-bottom: 1rem;
}

.header a,
.footer a,
.sidebar a,
.taxonomy-key {
    font-size: 0.81rem;
}
