@charset "UTF-8";
html {
  font-size: calc(1vh + 1vw);
}
body {
  margin: 0;
  word-break: break-all;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif;
  overflow: hidden;
}
img {
  height: auto;
  width: 100%;
  max-width: 40vw;
}
.clearfix::after {
  content: "";
  display: table;
  clear: both;
}
.left {
  float: left;
}
.right {
  float: right;
}
.hide {
  height: 0;
  opacity: 0;
}
.btn {
  text-decoration: none;
  cursor: pointer;
}
.control {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.no-break {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
a:hover {
  text-decoration: none !important;
}
body.loaded .top {
  display: none;
}
body.loaded .bottom {
  display: none;
}
.top img.logo,
.bottom img.logo {
  display: block;
  margin: 0 auto;
  position: relative;
}
.top,
.bottom {
  position: absolute;
  width: 100vw;
  height: 50vh;
  z-index: 99999;
  overflow: hidden;
}
.top {
  background: #282C34;
  top: calc(-50vh - 100px);
}
.top img.logo {
  bottom: -100%;
  transform: translateY(-50%);
}
.bottom {
  background: #282C34;
  top: calc(100vh + 100px);
}
.bottom img.logo {
  transform: translateY(-50%);
}
.header,
.footer {
  height: 5vh;
}
.header a,
.footer a {
  line-height: 5vh;
  display: block;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}
.header > ul,
.footer ul {
  margin: 0 1vw;
}
.menu > li,
.footer li {
  float: left;
}
.menu > li {
  position: relative;
}
.footer .social {
  float: left;
}
.footer .status {
  float: right;
}
.middle {
  height: 90vh;
}
.sidebar {
  width: 20vw;
  float: left;
}
.main {
  margin-left: 20vw;
  box-sizing: border-box;
  padding: 2vw 3vw;
  overflow-y: auto;
  position: relative;
}
.sidebar,
.main {
  height: 100%;
}
.sidebar.hide {
  display: none;
}
.sidebar.hide + .main {
  margin-left: 0 !important;
}
.intro {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}
.intro img.logo {
  display: inline;
}
.header ul ul {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 14em;
  z-index: 999;
}
.header li:hover > ul {
  display: block;
}
.main {
  font-size: 1rem;
  line-height: 2;
}
.header .sub-menu a {
  padding: 0 2vw;
}
.sidebar {
  overflow-y: auto;
}
.sidebar a {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.sidebar a,
.taxonomy-key {
  display: block;
}
.sidebar ul {
  margin-top: 1rem;
}
.sidebar a,
.taxonomy-key {
  display: block;
  line-height: 2;
  padding: 0 4%;
}
.logo-link {
  height: 5vh;
  box-sizing: border-box;
  padding-top: 1.2vh;
}
.logo {
  height: 70%;
  width: auto;
  display: block;
}
.logo-container {
  display: none;
}
body.wild-mode .logo-wild {
  display: list-item;
}
body.light-mode .logo-light {
  display: list-item;
}
body.dark-mode .logo-default {
  display: list-item;
}
body.wild-mode .intro .logo-wild {
  display: block;
}
body.light-mode .intro .logo-light {
  display: block;
}
body.dark-mode .intro .logo-default {
  display: block;
}
hr {
  margin: 1rem 0;
}
pre {
  overflow: auto;
  padding: 0.8rem 1rem;
}
code {
  overflow: auto;
  font-size: 0.8rem;
  font-family: 'Fira Code', Inconsolata, 'Deja Vu Sans Mono', 'Source Code Pro', Consolas, monospace;
  padding: 0.2rem 0.6rem;
}
.icon {
  width: 1rem;
  height: 1rem;
  display: inline-block;
}
.footer .icon {
  position: relative;
  top: 0.2rem;
}
.sidebar .icon {
  margin-right: 0.5rem;
  position: relative;
  top: 0.1rem;
}
.article-meta .icon {
  position: relative;
  top: calc(0.1rem + 2px);
}
.article-tags .icon {
  position: relative;
  top: -0.2rem;
}
.article-categories li:first-child .icon {
  top: calc(0.1rem + 1px);
}
.article {
  text-align: left;
}
.title {
  font-weight: bold;
  margin: 0 0 0.5rem 0;
}
.item {
  margin-bottom: 1rem;
}
.item .title {
  margin: 0;
}
.article-meta {
  font-size: 0.8rem;
  margin-bottom: 1rem;
}
.article-meta ul {
  list-style-type: none;
  margin: 0;
}
.article-meta-splitter {
  margin-bottom: 0.5rem;
}
.article-meta li {
  float: left;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
}
.article-meta li:first-child {
  margin-right: 0.5rem;
}
.main .article-tags a {
  padding: 0rem 0.8rem;
  border-radius: 1px;
  display: block;
  line-height: 1.4;
}
.main p a {
  margin: 0 0.3rem;
}
.title a,
.article-meta a,
.list a,
a.terms-title,
.terms-list a,
.pagination a {
  text-decoration: none;
}
.terms-list a {
  font-size: 0.8rem;
}
.pagination a {
  font-size: 0.8rem;
  padding: 0 0.5rem;
}
.pagination {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 2rem;
}
.pagination ul {
  list-style-type: none;
  margin-left: 0;
}
/* prism toolbar */
.code-toolbar > .toolbar a {
  border-bottom: none;
  display: block;
}
.local-info {
  font-size: 0.8rem;
  padding: 0.8rem;
  margin-bottom: 1rem;
}
.header a,
.footer a,
.sidebar a,
.taxonomy-key {
  font-size: 0.81rem;
}
.main a {
  color: #99CCFF;
}
.main .article-tags a {
  background: #327E36;
  color: #FFF;
}
.local-info,
.dialog label {
  background: #FFFF99;
  color: #333;
  box-shadow: 2px 3px 1px 1px #000;
}
/* man bg */
body,
.main,
.header .menu > li:hover,
.footer a:hover,
.logo-link,
.dialog {
  background: #282C34;
}
/* main fg */
.main,
blockquote.twitter-tweet,
.header .sub-menu a,
.sidebar a,
.taxonomy-key,
.main .title a,
.dialog .title {
  color: #C6C6C7;
}
.icon {
  background: #C6C6C7;
}
/* main minor */
.article-meta,
.item-meta,
.footnotes {
  color: #939BAB;
}
th,
td {
  border-bottom: 1px solid #939BAB;
}
hr {
  border: 1px solid #939BAB;
}
.pagination {
  background: #939BAB;
}
/* sidebar bg */
.sidebar {
  background: #16191D;
}
.header ul ul {
  box-shadow: 0 0.1em 0.2em 0 #060606;
}
.header ul ul,
.pagination a:hover {
  background: #1A1D21;
}
code {
  background: #1A1C21;
}
/* sidebar minor */
.count,
.taxonomy-key {
  color: #939394;
}
/* header, footer bg */
.header,
.footer,
.header .sub-menu li:hover,
.sidebar a:hover {
  background: #21252B;
}
/* header, footer fg */
.header a,
.footer a,
.footer time,
.pagination a {
  color: #797D85;
}
.footer .icon {
  background-color: #797D85;
}
.main h1,
.main h2,
.main h3,
.main h4,
.main h5,
.main h6 {
  text-shadow: 0 1px 1px #000;
}
.title {
  text-shadow: 0 1px 1px #000;
}
.dialog {
  width: 100vw;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow: hidden;
  font-size: 1rem;
}
.dialog .wrapper {
  padding: 1rem;
}
.dialog header,
.dialog main,
.dialog footer {
  margin-bottom: 2rem;
}
.dialog header {
  margin-top: -2rem;
}
.dialog div {
  margin-bottom: 1rem;
}
.dialog.show {
  height: 100vh;
}
.dialog label {
  display: block;
  margin-bottom: 1rem;
  padding: 0.8rem 1rem;
  box-shadow: none;
}
.dialog input {
  padding: 0.3rem;
}
.dialog input,
.dialog button {
  font-size: 1.2rem;
  float: right;
}
.dialog button {
  padding: 0.3rem 1rem;
  background: #3083FE;
  color: #FFF;
}
.dialog .close-dialog {
  font-size: 3rem;
  background: none;
  border: none;
}
.light-mode .dialog button {
  color: #3083FE;
}
.light-mode .main a {
  color: #3083FE;
}
.light-mode .main .article-tags a {
  background: #35CC81;
  color: #FFF;
}
.light-mode .local-info {
  background: #EFECAA;
  color: #000000;
  box-shadow: none;
}
/* man bg */
body.light-mode,
.light-mode .main {
  background: #FFF;
}
/* main fg */
.light-mode .main,
.light-mode blockquote.twitter-tweet,
.light-mode .sidebar a,
.light-mode .taxonomy-key,
.light-mode .main .title a,
.light-mode .dialog .title {
  color: #4C4C4C;
}
.light-mode .icon {
  background: #4C4C4C;
}
/* main minor */
.light-mode .article-meta,
.light-mode .item-meta,
.light-mode .footnotes {
  color: #999999;
}
.light-mode th,
.light-mode td {
  border-bottom: 1px solid #CCCCCC;
}
.light-mode hr {
  border: 1px solid #CCCCCC;
}
.light-mode .pagination {
  background: #CCCCCC;
}
/* sidebar bg */
.light-mode .sidebar {
  background: #EEEEEE;
}
.light-mode pre,
.light-mode code {
  background: #DDD;
}
/* sidebar fg */
.light-mode .sidebar a {
  color: #3F3F3F;
}
/* sidebar minor */
.light-mode .count,
.light-mode .taxonomy-key {
  color: #777777;
}
/* header, footer bg */
.light-mode .header,
.light-mode .footer,
.light-mode .header .sub-menu li:hover,
.light-mode .sidebar a:hover,
.light-mode .logo-link,
.light-mode .dialog,
.light-mode .dialog button {
  background: #DDD;
}
.light-mode .header .menu > li:hover,
.light-mode .footer a:hover {
  background: #CCCCCC;
}
/* sub-menu bg */
.light-mode .header ul ul {
  box-shadow: 0 0.1em 0.2em 0 #AAAAAA;
}
.light-mode .header ul ul,
.light-mode .pagination a:hover {
  background: #CCCCCC;
}
/* header, footer fg */
.light-mode .header a,
.light-mode .footer a,
.light-mode .footer time,
.light-mode .pagination a {
  color: #323232;
}
.light-mode .header .sub-menu a {
  color: #0B0B0B;
}
.light-mode .footer .icon {
  background-color: #323232;
}
.light-mode .main h1,
.light-mode .main h2,
.light-mode .main h3,
.light-mode .main h4,
.light-mode .main h5,
.light-mode .main h6,
.light-mode .title {
  text-shadow: none;
}
.light-mode .top,
.light-mode .bottom {
  background: #DDD;
}
.wild-mode .main h1,
.wild-mode .main h2,
.wild-mode .main h3,
.wild-mode .main h4,
.wild-mode .main h5,
.wild-mode .main h6,
.wild-mode .title {
  text-shadow: none;
}
.wild-mode .local-info {
  box-shadow: none;
}
.icon-tags {
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg data-name='Layer 2'%3E%3Cg data-name='pricetags'%3E%3Cpath d='M12.87 22a1.84 1.84 0 0 1-1.29-.53l-6.41-6.42a1 1 0 0 1-.29-.61L4 5.09a1 1 0 0 1 .29-.8 1 1 0 0 1 .8-.29l9.35.88a1 1 0 0 1 .61.29l6.42 6.41a1.82 1.82 0 0 1 0 2.57l-7.32 7.32a1.82 1.82 0 0 1-1.28.53zm-6-8.11l6 6 7.05-7.05-6-6-7.81-.73z'/%3E%3Ccircle cx='10.5' cy='10.5' r='1.5'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.icon-categories {
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg data-name='Layer 2'%3E%3Cpath d='M19.5 20.5h-15A2.47 2.47 0 0 1 2 18.07V5.93A2.47 2.47 0 0 1 4.5 3.5h4.6a1 1 0 0 1 .77.37l2.6 3.18h7A2.47 2.47 0 0 1 22 9.48v8.59a2.47 2.47 0 0 1-2.5 2.43zM4 13.76v4.31a.46.46 0 0 0 .5.43h15a.46.46 0 0 0 .5-.43V9.48a.46.46 0 0 0-.5-.43H12a1 1 0 0 1-.77-.37L8.63 5.5H4.5a.46.46 0 0 0-.5.43z' data-name='folder'/%3E%3C/g%3E%3C/svg%3E");
}
.icon-post {
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg data-name='Layer 2'%3E%3Cg data-name='file-text'%3E%3Cpath d='M15 16H9a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2zM9 14h3a1 1 0 0 0 0-2H9a1 1 0 0 0 0 2z'/%3E%3Cpath d='M19.74 8.33l-5.44-6a1 1 0 0 0-.74-.33h-7A2.53 2.53 0 0 0 4 4.5v15A2.53 2.53 0 0 0 6.56 22h10.88A2.53 2.53 0 0 0 20 19.5V9a1 1 0 0 0-.26-.67zM14 5l2.74 3h-2a.79.79 0 0 1-.74-.85zm3.44 15H6.56a.53.53 0 0 1-.56-.5v-15a.53.53 0 0 1 .56-.5H12v3.15A2.79 2.79 0 0 0 14.71 10H18v9.5a.53.53 0 0 1-.56.5z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.article-meta .icon-date {
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg data-name='Layer 2'%3E%3Cg data-name='calendar'%3E%3Cpath d='M18 4h-1V3a1 1 0 0 0-2 0v1H9V3a1 1 0 0 0-2 0v1H6a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3zM6 6h1v1a1 1 0 0 0 2 0V6h6v1a1 1 0 0 0 2 0V6h1a1 1 0 0 1 1 1v4H5V7a1 1 0 0 1 1-1zm12 14H6a1 1 0 0 1-1-1v-6h14v6a1 1 0 0 1-1 1z'/%3E%3Ccircle cx='8' cy='16' r='1'/%3E%3Cpath d='M16 15h-4a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.github {
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg data-name='Layer 2'%3E%3Cpath d='M12 1A10.89 10.89 0 0 0 1 11.77 10.79 10.79 0 0 0 8.52 22c.55.1.75-.23.75-.52v-1.83c-3.06.65-3.71-1.44-3.71-1.44a2.86 2.86 0 0 0-1.22-1.58c-1-.66.08-.65.08-.65a2.31 2.31 0 0 1 1.68 1.11 2.37 2.37 0 0 0 3.2.89 2.33 2.33 0 0 1 .7-1.44c-2.44-.27-5-1.19-5-5.32a4.15 4.15 0 0 1 1.11-2.91 3.78 3.78 0 0 1 .11-2.84s.93-.29 3 1.1a10.68 10.68 0 0 1 5.5 0c2.1-1.39 3-1.1 3-1.1a3.78 3.78 0 0 1 .11 2.84A4.15 4.15 0 0 1 19 11.2c0 4.14-2.58 5.05-5 5.32a2.5 2.5 0 0 1 .75 2v2.95c0 .35.2.63.75.52A10.8 10.8 0 0 0 23 11.77 10.89 10.89 0 0 0 12 1' data-name='github'/%3E%3C/g%3E%3C/svg%3E");
}
.twitter {
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg data-name='Layer 2'%3E%3Cpath d='M8.08 20A11.07 11.07 0 0 0 19.52 9 8.09 8.09 0 0 0 21 6.16a.44.44 0 0 0-.62-.51 1.88 1.88 0 0 1-2.16-.38 3.89 3.89 0 0 0-5.58-.17A4.13 4.13 0 0 0 11.49 9C8.14 9.2 5.84 7.61 4 5.43a.43.43 0 0 0-.75.24 9.68 9.68 0 0 0 4.6 10.05A6.73 6.73 0 0 1 3.38 18a.45.45 0 0 0-.14.84A11 11 0 0 0 8.08 20' data-name='twitter'/%3E%3C/g%3E%3C/svg%3E");
}
.facebook {
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg data-name='Layer 2'%3E%3Cpath d='M17 3.5a.5.5 0 0 0-.5-.5H14a4.77 4.77 0 0 0-5 4.5v2.7H6.5a.5.5 0 0 0-.5.5v2.6a.5.5 0 0 0 .5.5H9v6.7a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-6.7h2.62a.5.5 0 0 0 .49-.37l.72-2.6a.5.5 0 0 0-.48-.63H13V7.5a1 1 0 0 1 1-.9h2.5a.5.5 0 0 0 .5-.5z' data-name='facebook'/%3E%3C/g%3E%3C/svg%3E");
}
.behance {
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg data-name='Layer 2'%3E%3Cg data-name='behance'%3E%3Cpath d='M14.76 11.19a1 1 0 0 0-1 1.09h2.06a1 1 0 0 0-1.06-1.09zM9.49 12.3H8.26v1.94h1c1 0 1.44-.33 1.44-1s-.46-.94-1.21-.94zM10.36 10.52c0-.53-.35-.85-.95-.85H8.26v1.74h.85c.89 0 1.25-.32 1.25-.89z'/%3E%3Cpath d='M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zM9.7 15.2H7V8.7h2.7c1.17 0 1.94.61 1.94 1.6a1.4 1.4 0 0 1-1.12 1.43A1.52 1.52 0 0 1 12 13.37c0 1.16-1 1.83-2.3 1.83zm3.55-6h3v.5h-3zM17 13.05h-3.3v.14a1.07 1.07 0 0 0 1.09 1.19.9.9 0 0 0 1-.63H17a2 2 0 0 1-2.17 1.55 2.15 2.15 0 0 1-2.36-2.3v-.44a2.11 2.11 0 0 1 2.28-2.25A2.12 2.12 0 0 1 17 12.58z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.email {
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg data-name='Layer 2'%3E%3Cpath d='M19 4H5a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3zm0 2l-6.5 4.47a1 1 0 0 1-1 0L5 6z' data-name='email'/%3E%3C/g%3E%3C/svg%3E");
}
.rss {
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'  viewBox='0 0 24 24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Ccircle cx='6.18' cy='17.82' r='2.18'/%3E%3Cpath d='M4 4.44v2.83c7.03 0 12.73 5.7 12.73 12.73h2.83c0-8.59-6.97-15.56-15.56-15.56zm0 5.66v2.83c3.9 0 7.07 3.17 7.07 7.07h2.83c0-5.47-4.43-9.9-9.9-9.9z'/%3E%3C/svg%3E");
}
