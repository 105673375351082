.light-mode .dialog button {
    color: #3083FE;
}
.light-mode .main a {
    color: #3083FE;
}

.light-mode .main .article-tags a {
    background: #35CC81;
    color     : #FFF;
}

.light-mode .local-info {
    background: #EFECAA;
    color     : #000000;
    box-shadow: none;
}

/* man bg */
body.light-mode,
.light-mode .main {
    background: @light_main_bg;
}

/* main fg */
.light-mode .main,
.light-mode blockquote.twitter-tweet,
.light-mode .sidebar a,
.light-mode .taxonomy-key,
.light-mode .main .title a,
.light-mode .dialog .title {
    color: #4C4C4C;
}

.light-mode .icon {
    background: #4C4C4C;
}

/* main minor */
.light-mode .article-meta,
.light-mode .item-meta,
.light-mode .footnotes {
    color: #999999;
}

.light-mode th,
.light-mode td {
    border-bottom: 1px solid #CCCCCC;
}

.light-mode hr {
    border: 1px solid #CCCCCC;
}

.light-mode .pagination {
    background: #CCCCCC;
}

/* sidebar bg */
.light-mode .sidebar {
    background: #EEEEEE;
}


.light-mode pre,
.light-mode code {
    background: @light_header_bg;
}

/* sidebar fg */
.light-mode .sidebar a {
    color: #3F3F3F;
}

/* sidebar minor */
.light-mode .count,
.light-mode .taxonomy-key {
    color: #777777;
}


/* header, footer bg */
.light-mode .header,
.light-mode .footer,
.light-mode .header .sub-menu li:hover,
.light-mode .sidebar a:hover,
.light-mode .logo-link,
.light-mode .dialog,
.light-mode .dialog button {
    background: @light_header_bg;
}

.light-mode .header .menu>li:hover,
.light-mode .footer a:hover {
    background: #CCCCCC;
}

/* sub-menu bg */
.light-mode .header ul ul {
    box-shadow: 0 0.1em 0.2em 0 #AAAAAA;
}

.light-mode .header ul ul,
.light-mode .pagination a:hover {
    background: #CCCCCC;
}

/* header, footer fg */
.light-mode .header a,
.light-mode .footer a,
.light-mode .footer time,
.light-mode .pagination a {
    color: #323232;
}

.light-mode .header .sub-menu a {
    color: #0B0B0B;
}

.light-mode .footer .icon {
    background-color: #323232;
}

.light-mode .main h1,
.light-mode .main h2,
.light-mode .main h3,
.light-mode .main h4,
.light-mode .main h5,
.light-mode .main h6,
.light-mode .title {
    text-shadow: none;
}

.light-mode {
    .top, .bottom {
        background: @light_header_bg;
    }
}
